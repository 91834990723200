import { b2x } from '@b2x/react/src';
import classNames from 'classnames';

import { Breadcrumb } from '../Breadcrumb';
import { Container } from '../Container';
import { LandingTemplateAPageContentType } from '../contentTypes';
import { SliderLandingProduct } from '../slider/SliderLandingProduct';
import { Page } from './Page';

export interface LandingTemplateAPageProps {}

export const LandingTemplateAPage = (props: LandingTemplateAPageProps) => {
  const page = b2x.usePage<LandingTemplateAPageContentType>({
    secondStep: {
      populate: {
        products: b2x.appConfig.api?.productTilePopulate,
      },
    },
  });

  return (
    <Page className="landing-template-a-page" noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <Container fluid="always">
        <div className="py-3 pb-xl-5">
          <div className=" d-flex justify-content-center">
            <Breadcrumb />
          </div>
          {page?.content?.body.title && (
            <h1 className="text-uppercase text-center fw-bold m-0">{page.content.body.title}</h1>
          )}
        </div>
        {((page?.content?.body.header?.video && b2x.isVideoFromContentValid(page.content.body.header.video)) ||
          page?.content?.body.header?.img) && (
          <div className="px-xl-3 text-center mb-3 mb-xl-5">
            {page.content.body.header.video && b2x.isVideoFromContentValid(page.content.body.header.video) ? (
              <b2x.VideoFromContent {...page.content.body.header.video} />
            ) : (
              page.content.body.header.img && <b2x.ImageFromContent fluid {...page.content.body.header.img} />
            )}
          </div>
        )}
      </Container>
      <Container>
        {page?.content?.body.sectionContentRowList?.map((sectionRow) => (
          <b2x.Row className="py-4" key={sectionRow.contentSectionId}>
            {sectionRow.contentColList?.map((listCol) => {
              return (
                <>
                  {sectionRow.contentColList?.length && sectionRow.contentColList.length === 1 ? (
                    <>
                      <b2x.Col
                        className={classNames(
                          listCol.imgOrder === 'before' ? 'order-lg-1' : 'order-lg-2',
                          `px-0 px-lg-3 col-12 col-lg-${listCol.imgSize}`
                        )}
                      >
                        <h3 className="d-block d-lg-none h1 text-center fw-bold pb-3">
                          {b2x.formatHtml(listCol.title)}
                        </h3>
                        <b2x.ImageFromContent className="img-fluid" {...listCol.img} fluid />
                      </b2x.Col>
                      <b2x.Col
                        className={classNames(
                          listCol.imgOrder === 'before' ? 'order-lg-2' : 'order-lg-1',
                          'd-flex align-items-center'
                        )}
                      >
                        <div className={classNames(listCol.imgOrder === 'before' ? 'px-4' : 'px-lg-5 px-4 mx-lg-3')}>
                          <h3 className="d-none d-lg-block h1 fw-bold pb-3">{b2x.formatHtml(listCol.title)}</h3>
                          <h6 className="pt-3 pt-lg-0">{b2x.formatHtml(listCol.content)}</h6>
                        </div>
                      </b2x.Col>
                    </>
                  ) : (
                    <>
                      <b2x.Col className="col-12 col-lg-6 pb-4">
                        <b2x.Row>
                          <b2x.Col className="d-block d-lg-none">
                            <h1 className="fw-bold text-center pb-4">{b2x.formatHtml(listCol.title)}</h1>
                          </b2x.Col>
                          <b2x.Col className={`px-0 px-lg-3 col-${listCol.imgSize}`}>
                            <b2x.ImageFromContent className="pb-4" {...listCol.img} fluid />
                            <h1 className="d-none d-lg-block fw-bold text-center pb-4">
                              {b2x.formatHtml(listCol.title)}
                            </h1>
                            <h6 className="px-lg-5 px-4 mx-lg-3 pb-4 pb-lg-0">{b2x.formatHtml(listCol.content)}</h6>
                          </b2x.Col>
                        </b2x.Row>
                      </b2x.Col>
                    </>
                  )}
                </>
              );
            })}
          </b2x.Row>
        ))}
      </Container>
      {page?.products && (
        <div className="overflow-hidden mb-5">
          <Container>
            <h3 className="text-center h1 fw-bold mb-5 mt-0 mt-lg-4">{page.content?.body.sectionSlider?.title}</h3>
            <b2x.Row>
              <b2x.Col className="d-none d-lg-block" size={'auto'}>
                <b2x.ImageFromContent {...page.content?.body.sectionSlider?.img} fluid />
              </b2x.Col>
              <b2x.Col size={{ lg: 7, md: 12, xl: 8 }}>
                <b2x.Listing name="Landing page - Slider" products={page.products}>
                  <SliderLandingProduct products={page.products} />
                </b2x.Listing>
              </b2x.Col>
            </b2x.Row>
          </Container>
        </div>
      )}
    </Page>
  );
};
