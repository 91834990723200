import 'swiper/scss/navigation'; // Navigation module
import 'swiper/scss/pagination'; // Pagination module
import 'swiper/scss'; // core Swiper
import './Slideshow.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { Swiper, SwiperSlide } from 'swiper/react';

import { Container } from '../Container';
import { BannerSlideContentSection } from '../contentTypes';
import { SliderButton } from './SliderButton';

export interface SlideshowProps {
  slides: b2x.contentTypes.ContentSectionList<BannerSlideContentSection>;
}

export const Slideshow = ({ slides }: SlideshowProps) => {
  const sliderUUID = React.useMemo(() => `slider-${b2x.createUUID()}`, []);
  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <div className={`slideshow ${sliderUUID} position-relative bg-black`}>
      <b2x.EqualHeight timeout={200}>
        <div className="slider">
          <Swiper
            autoplay={{ delay: 15000 }}
            loop
            modules={[Pagination, Navigation, Autoplay]}
            navigation={{ nextEl: `.${sliderUUID} .swiper-button-next`, prevEl: `.${sliderUUID} .swiper-button-prev` }}
            pagination={{
              clickable: true,
              el: `.${sliderUUID} .pagination`,
              type: 'bullets',
            }}
            slidesPerView={1}
            spaceBetween={0}
          >
            {slides.map((slide) => (
              <SwiperSlide key={slide.contentSectionId}>
                {(slideData) => (
                  <div className={'slide'}>
                    <b2x.EqualHeightElement name="slide">
                      {b2x.isVideoAsBackgroundFromContentValid(slide.videoBackground) ? (
                        <div className="position-relative h-100 w-100">
                          <b2x.VideoAsBackgroundFromContent {...slide.videoBackground} />
                        </div>
                      ) : (
                        slide.img && <b2x.ImageFromContent {...slide.img} className="w-100" fluid />
                      )}
                      {slide.cta && <b2x.DeprecatedCta className="stretched-link" cta={slide.cta} />}
                    </b2x.EqualHeightElement>
                    <div className="message">
                      <Container>
                        <div className="inner-container">
                          <b2x.Row
                            className={classnames(
                              { 'justify-content-start': slide.copy?.alignment === 'left' },
                              { 'justify-content-center': slide.copy?.alignment === 'center' },
                              { 'justify-content-end': slide.copy?.alignment === 'right' }
                            )}
                          >
                            <b2x.Col className="text-white" size={{ lg: 6, xs: 12 }}>
                              {slide.copy?.title && (
                                <h3
                                  className={classnames(
                                    'fw-bold lh-1 mb-3',
                                    { h3: b2x.untilBreakpoint('md', currentBreakpoint) },
                                    { 'display-1': !b2x.untilBreakpoint('md', currentBreakpoint) }
                                  )}
                                >
                                  {b2x.formatHtml(slide.copy.title)}
                                </h3>
                              )}
                              {slide.copy?.description && (
                                <div
                                  className={classnames(
                                    'fw-medium',
                                    { 'mb-3': b2x.untilBreakpoint('md', currentBreakpoint) },
                                    { 'h4 mb-4': !b2x.untilBreakpoint('md', currentBreakpoint) }
                                  )}
                                >
                                  {b2x.formatHtml(slide.copy.description)}
                                </div>
                              )}
                              {slide.cta?.label && (
                                <div className="d-grid d-sm-block">
                                  <b2x.DeprecatedCta className="btn btn-outline-white fw-medium" cta={slide.cta}>
                                    {slide.cta.label}
                                  </b2x.DeprecatedCta>
                                </div>
                              )}
                            </b2x.Col>
                          </b2x.Row>
                        </div>
                      </Container>
                    </div>
                  </div>
                )}
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
        <div className="slider-navigation">
          <b2x.EqualHeightElement name="slide">
            <div className="h-100 d-flex align-items-center">
              <div className="w-100">
                <div className="position-relative">
                  <SliderButton direction="left" size="large" />
                  <SliderButton direction="right" size="large" />
                </div>
              </div>
            </div>
          </b2x.EqualHeightElement>
        </div>
        <div className="slider-pagination">
          <b2x.EqualHeightElement name="slide">
            <div className="pagination"></div>
          </b2x.EqualHeightElement>
        </div>
      </b2x.EqualHeight>
    </div>
  );
};
