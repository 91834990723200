import './HomePage.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React from 'react';

import { Container } from '../Container';
import { HomePageContentType } from '../contentTypes';
import { PopUpNewsletterModal } from '../PopupNewsletterModal';
import { Section } from '../Section';
import { SliderA } from '../slider/SliderA';
import { SliderAmbassadors } from '../slider/SliderAmbassadors';
import { SliderProduct } from '../slider/SliderProduct';
import { Slideshow } from '../slider/Slideshow';
import { Page } from './Page';

export interface HomePageProps {}

export const HomePage = (props: HomePageProps) => {
  const page = b2x.usePage<HomePageContentType>({
    secondStep: {
      populate: {
        products: b2x.appConfig.api?.productTilePopulate,
      },
    },
  });

  const currentBreakpoint = b2x.useBreakpoint();
  const { showModal } = b2x.useModals();
  const { pageReady } = b2x.useAppContext();

  React.useEffect(() => {
    const modalAlreadyShown = b2x.cookies.getBoolean('homePageNewsletterModal');
    if (!modalAlreadyShown && pageReady) {
      b2x.wait(3000).then(() => {
        showModal({
          children: <PopUpNewsletterModal />,
          onClose: () => {
            b2x.cookies.setBoolean('homePageNewsletterModal', true, { expires: 1 });
          },
          size: 'default',
          title: '',
        });
      });
    }
  }, [pageReady, showModal]);

  return (
    <Page className="home bg-black" noPaddingBottom noPaddingTop>
      {page?.content?.body.slideshow && (
        <Section className="slideshow mb-3">
          <Slideshow slides={page.content.body.slideshow} />
        </Section>
      )}
      {page?.content?.body.boxSectionList && (
        <Section className="box-section pb-3">
          <SliderA itemList={page.content.body.boxSectionList} />
        </Section>
      )}
      {page?.content?.body.productTab && (
        <Section className="product-tab text-white">
          <div className="pt-3 pb-5 py-lg-5">
            <Container>
              <b2x.Row>
                <b2x.Col>
                  <div className="mt-lg-5 text-center">
                    {page.content.body.productTab.header?.title && (
                      <h3
                        className={classnames(
                          'fw-bold mb-2',
                          { h1: !b2x.untilBreakpoint('md', currentBreakpoint) },
                          { h3: b2x.untilBreakpoint('md', currentBreakpoint) }
                        )}
                      >
                        {b2x.formatHtml(page.content.body.productTab.header.title)}
                      </h3>
                    )}
                    {page.content.body.productTab.header?.subtitle && (
                      <p className={classnames('m-0', { h2: !b2x.untilBreakpoint('md', currentBreakpoint) })}>
                        {b2x.formatHtml(page.content.body.productTab.header.subtitle)}
                      </p>
                    )}
                  </div>
                </b2x.Col>
              </b2x.Row>
            </Container>
            <div className="py-3 py-lg-5 text-center">
              {page.products && (
                <Container>
                  <b2x.Listing name="Home page - Slider" products={page.products}>
                    <SliderProduct products={page.products} />
                  </b2x.Listing>
                </Container>
              )}
            </div>
            <Container>
              <b2x.Row className="justify-content-center">
                <b2x.Col size={{ lg: 10, xs: 12 }}>
                  <div className="mb-lg-5 pt-3 pt-lg-5 border-top  text-center">
                    {page.content.body.productTab.footer?.title && (
                      <h3
                        className={classnames(
                          'fw-bold mb-3 mb-lg-5',
                          { h1: !b2x.untilBreakpoint('md', currentBreakpoint) },
                          { h3: b2x.untilBreakpoint('md', currentBreakpoint) }
                        )}
                      >
                        {b2x.formatHtml(page.content.body.productTab.footer.title)}
                      </h3>
                    )}
                    {page.content.body.productTab.footer?.cta && (
                      <div className="d-grid d-sm-block">
                        <b2x.CtaFromContent
                          ctaProps={{ button: { className: 'fw-medium text-uppercase', variant: 'outline-secondary' } }}
                          {...page.content.body.productTab.footer.cta}
                        />
                      </div>
                    )}
                  </div>
                </b2x.Col>
              </b2x.Row>
            </Container>
          </div>
        </Section>
      )}
      {page?.content?.body.boxFullSectionList && page.content.body.boxFullSectionList.length > 0 && (
        <Section className="box-full-section">
          {page.content.body.boxFullSectionList.map((boxFullSection) => (
            <div className="position-relative" key={boxFullSection.contentSectionId}>
              <b2x.ImageFromContent {...boxFullSection.img} className="w-100" fluid />
              {(boxFullSection.copy || boxFullSection.cta) && (
                <div
                  className={classnames(
                    'message py-4 py-md-5 py-lg-0',
                    !b2x.untilBreakpoint('md', currentBreakpoint) && boxFullSection.copy?.desktopColor === 'white'
                      ? 'text-white'
                      : 'text-black',
                    b2x.untilBreakpoint('md', currentBreakpoint) && boxFullSection.copy?.mobileColor === 'white'
                      ? 'bg-black text-white'
                      : 'text-black'
                  )}
                >
                  <Container>
                    <b2x.Row
                      className={classnames(
                        { 'justify-content-start': boxFullSection.copy?.alignment === 'left' },
                        { 'justify-content-center': boxFullSection.copy?.alignment === 'center' },
                        { 'justify-content-end': boxFullSection.copy?.alignment === 'right' }
                      )}
                    >
                      <b2x.Col size={{ lg: 5, xs: 12 }}>
                        {boxFullSection.copy?.title && (
                          <h3
                            className={classnames(
                              'fw-bold',
                              { 'h1 mb-4': !b2x.untilBreakpoint('lg', currentBreakpoint) },
                              { 'h3 mb-2': b2x.untilBreakpoint('lg', currentBreakpoint) }
                            )}
                          >
                            {b2x.formatHtml(boxFullSection.copy.title)}
                          </h3>
                        )}
                        {boxFullSection.copy?.description && (
                          <div
                            className={classnames(
                              { 'h2 mb-4': !b2x.untilBreakpoint('lg', currentBreakpoint) },
                              { 'mb-3': b2x.untilBreakpoint('lg', currentBreakpoint) }
                            )}
                          >
                            {b2x.formatHtml(boxFullSection.copy.description)}
                          </div>
                        )}
                        {boxFullSection.cta?.label && (
                          <b2x.CtaFromContent
                            ctaProps={{
                              button: {
                                className: classnames(
                                  'fw-medium text-uppercase',
                                  !b2x.untilBreakpoint('md', currentBreakpoint) &&
                                    boxFullSection.copy?.desktopColor === 'white'
                                    ? 'btn-outline-white'
                                    : 'btn-outline-secondary',
                                  b2x.untilBreakpoint('md', currentBreakpoint) &&
                                    boxFullSection.copy?.mobileColor === 'white'
                                    ? 'btn-outline-white'
                                    : 'btn-outline-secondary'
                                ),
                              },
                            }}
                            {...boxFullSection.cta}
                          />
                        )}
                      </b2x.Col>
                    </b2x.Row>
                  </Container>
                </div>
              )}
            </div>
          ))}
        </Section>
      )}
      <Section className="ambassadors overflow-hidden py-lg-5" container="xxl">
        <div className="py-5">
          {page?.content?.body.ambassador && page.content.body.ambassador.title && (
            <div className="mb-5 text-center text-white">
              <h3 className="h1 fw-bold mb-5">{b2x.formatHtml(page.content.body.ambassador.title)}</h3>
            </div>
          )}
          <SliderAmbassadors />
        </div>
      </Section>
      <p>
        <iframe
          src="https://widget.taggbox.com/148066"
          style={{ border: 'none', height: '320px', width: '100%' }}
          title="taggbox"
        ></iframe>
      </p>
    </Page>
  );
};
