import { b2x } from '@b2x/react/src';
import React from 'react';

import { Box } from '../Box';
import { Button } from '../Button';
import { SignUpContentType } from '../contentTypes';
import { t } from '../i18n/i18n';

export interface SignUpUserFormProps extends SignUpContentType {
  canProceedWithoutRegistration?: boolean;
  handleRegistrationSuccess?(): void;
  newsletterDiscount?: string;
}

export const SignUpUserForm = ({
  canProceedWithoutRegistration,
  handleRegistrationSuccess,
  newsletterDiscount,
  registerMe,
  subTitle,
  title,
}: SignUpUserFormProps) => {
  const [signingUp, setSigningUp] = React.useState<boolean>(!canProceedWithoutRegistration);

  const handleButtonClick = React.useCallback(() => setSigningUp(true), []);

  const handleCancelButtonClick = React.useCallback(() => setSigningUp(false), []);

  return (
    <Box fullHeight>
      {signingUp ? (
        <div className="mb-4">
          <h5 className="fw-bold text-uppercase text-center mb-3">{b2x.formatHtml(title)}</h5>
          <div className="text-center mb-3">{b2x.formatHtml(newsletterDiscount)}</div>
          <b2x.CustomerForm
            onCancel={canProceedWithoutRegistration ? handleCancelButtonClick : undefined}
            onSuccess={handleRegistrationSuccess}
          />
        </div>
      ) : (
        <div className="mb-4 ">
          <h5 className="text-uppercase fw-bold text-center mb-1 d-none d-md-block">{b2x.formatHtml(title)}</h5>
          <div className="border-bottom mb-3 border-2 d-md-none">
            <h5 className="text-uppercase text-center mb-3">{b2x.formatHtml(title)}</h5>
          </div>
          {<div className="text-center mb-3 mb-md-5">{b2x.formatHtml(subTitle)}</div>}
          <div className="mb-5">
            <b2x.CartEmailFormHelper variant="whois">
              {({ fieldsHelper, formik }) => (
                <>
                  <b2x.FormGroup {...fieldsHelper.email.formGroup} label={undefined}>
                    <b2x.TextInput {...fieldsHelper.email.textInput} />
                  </b2x.FormGroup>
                  <b2x.FormGroup {...fieldsHelper.checkbox.formGroup}>
                    <b2x.Checkbox className="" {...fieldsHelper.checkbox.checkbox} />
                  </b2x.FormGroup>
                  <div className="d-grid">
                    <b2x.Button {...fieldsHelper.buttons.submit} />
                  </div>
                </>
              )}
            </b2x.CartEmailFormHelper>
          </div>
          {<div className="text-center mb-3">{b2x.formatHtml(registerMe?.content)}</div>}
          <div className="d-grid">
            <Button label={t('misc.register')} onClick={handleButtonClick} variant="outline-primary" />
          </div>
        </div>
      )}
    </Box>
  );
};
