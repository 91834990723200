import { b2x } from '@b2x/react/src';

import { AmbassadorList } from '../AmbassadorList';
import { Breadcrumb } from '../Breadcrumb';
import { Container } from '../Container';
import { AmbassadorsPageContentType } from '../contentTypes';
import { Page } from './Page';

export interface AmbassadorsPageProps {}

export const AmbassadorsPage = (props: AmbassadorsPageProps) => {
  const page = b2x.usePage<AmbassadorsPageContentType>();

  return (
    <Page className="ambassador-page" noPaddingTop thingsToLoadBeforePageReady={[page]}>
      <Container fluid="always">
        <div className="py-3 pb-xl-5">
          <div className=" d-flex justify-content-center">
            <Breadcrumb />
          </div>
          {page?.content?.body.title && (
            <h1 className="text-uppercase text-center fw-bold m-0">{page.content.body.title}</h1>
          )}
        </div>
        <div className="px-xl-3 text-center">
          {page?.content?.body.video && b2x.isVideoFromContentValid(page.content.body.video) ? (
            <b2x.VideoFromContent {...page.content.body.video} />
          ) : (
            page?.content?.body.image && <b2x.ImageFromContent fluid {...page.content.body.image} />
          )}
        </div>
      </Container>
      {page?.content?.body.content && (
        <Container>
          <div className="py-3 py-lg-5 text-center">
            <b2x.Row className="justify-content-center">
              <b2x.Col size={{ lg: 10, xs: 12 }}>{b2x.formatHtml(page.content.body.content)}</b2x.Col>
            </b2x.Row>
          </div>
        </Container>
      )}
      <AmbassadorList />
    </Page>
  );
};
