import './Breadcrumb.scss';

import { b2x } from '@b2x/react/src';

export interface BreadcrumbProps extends b2x.BreadcrumbProps {
  alignment?: 'start' | 'center' | 'end';
}

export const Breadcrumb = ({ alignment, ...otherProps }: BreadcrumbProps) => {
  return (
    <b2x.Breadcrumb
      {...otherProps}
      classNameObject={{
        a: 'text-decoration-none',
        component: 'extra-small text-uppercase',
        ol: `justify-content-${alignment} mb-0`,
      }}
    />
  );
};
