import './DesktopHeader.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';
import React, { useCallback, useEffect, useRef, useState } from 'react';

import { useAppStaticContext } from './AppContext';
import { Button } from './Button';
import { Container } from './Container';
import { HeaderContentType, HeaderMenuItemContentType, HeaderMenuItemCtaContentType } from './contentTypes';
import { Icon } from './Icon';
import logo from './images/logo.svg';
import logoSmall from './images/logo-small.svg';

export interface DesktopHeaderProps {
  activeCategory?: b2x.MenuApiDto;
  content?: b2x.ContentApiDto<HeaderContentType>;
  firstRowRef: React.RefObject<HTMLDivElement>;
  localeBoxVisible: boolean;
  recalculateHeaderHeight(): void;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  toggleLocaleBoxVisible(): void;
  visibleFrom: b2x.Breakpoint;
}

export const DesktopHeader = ({
  activeCategory,
  content,
  firstRowRef,
  localeBoxVisible,
  recalculateHeaderHeight,
  setActiveCategory,
  toggleLocaleBoxVisible,
  visibleFrom,
}: DesktopHeaderProps) => {
  const { setThingsToLoadBeforeAppReady } = b2x.useAppStaticContext();

  const menu = b2x.useMenu('MENU_DESKTOP', { populate: { content: true } });

  React.useEffect(() => {
    setThingsToLoadBeforeAppReady((prevState) => ({ ...prevState, MENU_DESKTOP: menu }));
  }, [menu, setThingsToLoadBeforeAppReady]);

  const subMenuDropdownRef = useRef<HTMLDivElement>(null);

  return (
    <div
      className={classnames('desktop-header sticky-top bg-black', `d-none d-${visibleFrom}-block`, {
        'show-white-background': localeBoxVisible || activeCategory,
      })}
      ref={firstRowRef}
    >
      <div className={classnames('desktop-header-main py-4')}>
        <Container>
          <b2x.Row gap={0}>
            <b2x.Col size={'auto'}>
              <div className="d-flex align-items-center h-100">
                <Logo recalculateHeaderHeight={recalculateHeaderHeight} />
              </div>
            </b2x.Col>
            <b2x.Col className="header-search-col">
              <div className="d-flex align-items-center h-100 w-100 px-5">
                <div className="w-100 rounded-pill border border-white overflow-hidden">
                  <b2x.SimpleSearchForm submitIconName="search" />
                </div>
                <div></div>
              </div>
            </b2x.Col>
            <b2x.Col size={'auto'}>
              <div className="d-flex align-items-center h-100">
                {content?.body.headerDesktop.ctaList && (
                  <div className="heaader-cta-container d-flex gap-5 me-5">
                    {content.body.headerDesktop.ctaList.map(
                      (item) =>
                        item.cta && (
                          <b2x.CtaFromContent
                            ctaProps={{
                              button: {
                                className: 'text-decoration-none text-uppercase text-white fw-bold btn-blank',
                              },
                            }}
                            {...item.cta}
                            key={item.contentSectionId}
                          />
                        )
                    )}
                  </div>
                )}
                <div className="d-flex align-items-center">
                  <Toggles />
                </div>
              </div>
            </b2x.Col>
          </b2x.Row>
        </Container>
      </div>
      <div className="desktop-header-menu bg-primary">
        <Container>
          <Menu
            activeCategory={activeCategory}
            menu={menu}
            setActiveCategory={setActiveCategory}
            subMenuDropdownRef={subMenuDropdownRef}
          />
        </Container>
      </div>
      {activeCategory && (
        <SubMenuDropdown
          activeCategory={activeCategory}
          setActiveCategory={setActiveCategory}
          subMenuDropdownRef={subMenuDropdownRef}
        />
      )}
      {localeBoxVisible && <LocaleBox toggleLocaleBoxVisible={toggleLocaleBoxVisible} />}
    </div>
  );
};

interface TogglesProps {}

const Toggles = (props: TogglesProps) => {
  const { session } = b2x.useAppContext();
  const { getPagePath } = b2x.useAppStaticContext();

  const { showAccountOffcanvas, showCartOffcanvas } = useAppStaticContext();

  return (
    <div className="toggles">
      <div className="hstack gap-3">
        {session?.customer ? (
          <b2x.router.Link className="lh-1 text-primary p-2" to={getPagePath('SITE_ACCOUNT')}>
            <Icon name="account" size={25} />
          </b2x.router.Link>
        ) : (
          <Button
            className="text-white"
            iconEnd={{ name: 'account', size: 25 }}
            onClick={showAccountOffcanvas}
            variant="blank"
          />
        )}
        <div className="vr bg-white opacity-100"></div>
        {b2x.appConfig.enableWishlist &&
          (session?.customer ? (
            <b2x.router.Link className="btn-wishlist lh-1 p-2" to="/account/area/wishlist">
              <Button className="position-relative text-white" variant="blank">
                <Icon name={'wishlist'} size={25} />
                {((session.wishlist?.products && session.wishlist.products.length > 0) ||
                  (session.wishlist?.skus && session.wishlist.skus.length > 0)) && (
                  <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
                    {(session.wishlist.products ? session.wishlist.products.length : 0) +
                      (session.wishlist.skus ? session.wishlist.skus.length : 0)}
                  </span>
                )}
              </Button>
            </b2x.router.Link>
          ) : (
            <Button
              className="btn-wishlist text-white p-2"
              iconEnd={{ name: 'wishlist', size: 25 }}
              onClick={showAccountOffcanvas}
              variant="blank"
            />
          ))}
        <div className="vr bg-white opacity-100"></div>
        <Button className="btn-cart position-relative p-2 text-white" onClick={showCartOffcanvas} variant="blank">
          <Icon name={'cart'} size={25} />
          {session?.cart?.itemsNumber !== undefined && session.cart.itemsNumber > 0 && (
            <span className="position-absolute top-0 start-100 rounded-pill translate-middle badge bg-primary">
              {session.cart.itemsNumber}
            </span>
          )}
        </Button>
      </div>
    </div>
  );
};

interface LogoProps {
  recalculateHeaderHeight(): void;
  small?: boolean;
}

const Logo = ({ recalculateHeaderHeight, small }: LogoProps) => {
  return (
    <b2x.router.Link to="/">
      <b2x.Image onLoad={recalculateHeaderHeight} src={small ? logoSmall : logo} />
    </b2x.router.Link>
  );
};

interface MenuItemContainerProps extends React.PropsWithChildren {
  activeCategory?: b2x.MenuApiDto;
  firstLevelCategory: b2x.MenuApiDto;
  menuItemRef?: React.RefObject<HTMLDivElement>;
}

const MenuItemContainer = ({ activeCategory, children, firstLevelCategory, menuItemRef }: MenuItemContainerProps) => {
  return (
    <div
      className={classnames('menu-item d-flex align-items-center', {
        active: activeCategory && activeCategory.id === firstLevelCategory.id,
      })}
      ref={menuItemRef}
    >
      {children}
    </div>
  );
};

interface DropdownMenuButtonProps {
  activeCategory?: b2x.MenuApiDto;
  addRef(ref: React.RefObject<HTMLDivElement>): void;
  firstLevelCategory: b2x.MenuApiDto;
  removeRef(ref: React.RefObject<HTMLDivElement>): void;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
}

const DropdownMenuButton = ({
  activeCategory,
  addRef,
  firstLevelCategory,
  removeRef,
  setActiveCategory,
}: DropdownMenuButtonProps) => {
  const menuItemRef = useRef<HTMLDivElement>(null);
  const timeoutOnActivateCategory = useRef<NodeJS.Timeout>();

  useEffect(() => {
    addRef(menuItemRef);

    return () => removeRef(menuItemRef);
  }, [addRef, removeRef]);

  const onActivateCategory = useCallback(() => {
    timeoutOnActivateCategory.current = setTimeout(() => setActiveCategory(firstLevelCategory), 250);
  }, [setActiveCategory, firstLevelCategory]);

  const onDeactivateCategory = useCallback(() => clearTimeout(timeoutOnActivateCategory.current), []);
  useEffect(() => onDeactivateCategory, [onDeactivateCategory]);

  return (
    <MenuItemContainer
      activeCategory={activeCategory}
      firstLevelCategory={firstLevelCategory}
      menuItemRef={menuItemRef}
    >
      <Button
        className={classnames('text-decoration-none text-uppercase fw-bold text-white px-0 py-3 lh-1 text-nowrap', {
          active: activeCategory && activeCategory.id === firstLevelCategory.id,
        })}
        label={firstLevelCategory.label}
        onClick={onActivateCategory}
        onMouseEnter={onActivateCategory}
        onMouseLeave={onDeactivateCategory}
        variant="blank"
      />
    </MenuItemContainer>
  );
};

interface MenuProps {
  activeCategory?: b2x.MenuApiDto;
  menu?: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const Menu = ({ activeCategory, menu, setActiveCategory, subMenuDropdownRef }: MenuProps) => {
  const idActiveCategory = activeCategory?.id;
  const onHoverOutsideCallback = useCallback(() => {
    if (idActiveCategory) {
      setActiveCategory(undefined);
    }
  }, [idActiveCategory, setActiveCategory]);

  const { addRef, removeRef } = b2x.useOnHoverOutside(onHoverOutsideCallback, [subMenuDropdownRef], 100);

  return (
    <div className="menu d-flex justify-content-between">
      {menu?.children.map(
        (firstLevelCategory) =>
          (firstLevelCategory.children.length > 0 || firstLevelCategory.link !== undefined) &&
          (firstLevelCategory.children.length > 0 ? (
            <DropdownMenuButton
              activeCategory={activeCategory}
              addRef={addRef}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
              removeRef={removeRef}
              setActiveCategory={setActiveCategory}
            />
          ) : (
            <MenuItemContainer
              activeCategory={activeCategory}
              firstLevelCategory={firstLevelCategory}
              key={firstLevelCategory.id}
            >
              <b2x.router.Link
                className={classnames(
                  'Button btn btn-link text-decoration-none text-uppercase text-white fw-bold px-0',
                  {
                    active: activeCategory && activeCategory.id === firstLevelCategory.id,
                  }
                )}
                to={firstLevelCategory.link}
              >
                {firstLevelCategory.label}
              </b2x.router.Link>
            </MenuItemContainer>
          ))
      )}
    </div>
  );
};

interface SubMenuDropdownProps {
  activeCategory: b2x.MenuApiDto;
  setActiveCategory: React.Dispatch<React.SetStateAction<b2x.MenuApiDto | undefined>>;
  subMenuDropdownRef: React.RefObject<HTMLDivElement>;
}

const SubMenuDropdown = ({ activeCategory, setActiveCategory, subMenuDropdownRef }: SubMenuDropdownProps) => {
  const close = React.useCallback(() => {
    setActiveCategory(undefined);
  }, [setActiveCategory]);

  const [isMounted, setIsMounted] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => setIsMounted(true), 50);
    return () => clearTimeout(timeout);
  }, []);

  b2x.useKeyPress('Escape', close);

  return (
    <>
      <div
        className={classnames({ mounted: isMounted }, 'sub-menu-dropdown w-100 text-white')}
        ref={subMenuDropdownRef}
      >
        <Container>
          <b2x.Row>
            {activeCategory.children.map((secondLevelCategory, index) => {
              let otherSize = 3;

              let _tagSize =
                secondLevelCategory.tags
                  ?.find((tag) => tag.startsWith('col-'))
                  ?.valueOf()
                  .replace('col-', '') ?? otherSize;

              _tagSize = typeof _tagSize === 'string' ? parseInt(_tagSize) : otherSize;

              otherSize = _tagSize > 0 && _tagSize <= 12 ? _tagSize : otherSize;

              const columnSize = index === 0 ? 2 : index === 1 ? '' : otherSize;

              return (
                <b2x.Col
                  className={classnames('main-column', { 'bg-primary': index === 0 })}
                  key={secondLevelCategory.id}
                  size={columnSize as b2x.ResponsiveColSize}
                >
                  {secondLevelCategory.children.length > 0 ? (
                    <div className="h-100 py-5 px-2">
                      <RecursiveMenuItem close={close} item={secondLevelCategory} />
                    </div>
                  ) : (
                    secondLevelCategory.content &&
                    b2x.typedContent<HeaderMenuItemContentType>(secondLevelCategory.content, (content) => {
                      return (
                        <>
                          {content.body.itemList &&
                            (content.body.itemList.length > 1 ? (
                              <div className="py-5">
                                {secondLevelCategory.label}
                                {content.body.itemList.map((item) => (
                                  <div key={item.contentSectionId}></div>
                                ))}
                              </div>
                            ) : (
                              content.body.itemList.map((item) => (
                                <div
                                  className="position-relative h-100 w-100 py-5 d-flex align-items-end"
                                  key={item.contentSectionId}
                                >
                                  <b2x.ImageAsBackgroundFromContent
                                    className="position-absolute start-0 top-0 w-100 h-100"
                                    {...item.img}
                                  />
                                  <div
                                    className="w-100 d-flex justify-content-center text-uppercase"
                                    style={{ zIndex: 10 }}
                                  >
                                    <b2x.CtaFromContent
                                      {...item.cta}
                                      ctaProps={{ button: { className: 'fw-medium btn-sm lh-lg' } }}
                                      variant="primary"
                                    />
                                  </div>
                                </div>
                              ))
                            ))}
                        </>
                      );
                    })
                  )}
                </b2x.Col>
              );
            })}
          </b2x.Row>
        </Container>
      </div>
    </>
  );
};

interface RecursiveMenuItemProps {
  close?(): void;
  deep?: number;
  item?: b2x.MenuApiDto;
}

const RecursiveMenuItem = ({ close, deep = 1, item }: RecursiveMenuItemProps) => {
  let tagsColumnsSize: string | number | undefined = item?.tags?.find((tag) => tag.startsWith('cols-'))?.valueOf();

  if (tagsColumnsSize) {
    tagsColumnsSize = tagsColumnsSize.replace('cols-', '');

    if (typeof parseInt(tagsColumnsSize) === 'number') {
      tagsColumnsSize = parseInt(tagsColumnsSize);
      tagsColumnsSize = tagsColumnsSize > 0 && tagsColumnsSize < 12 ? tagsColumnsSize : undefined;
    } else {
      tagsColumnsSize = undefined;
    }
  }

  return (
    <div className={classnames('recursive-menu-item', 'h-100', `deep-${deep}`, item?.code)}>
      {item?.children.find((child) => child.children.length > 0) ? (
        <div className="h-100 d-flex flex-column justify-content-between">
          <b2x.Row cols={tagsColumnsSize as b2x.ResponsiveColSize}>
            {item.children.map((child) => (
              <b2x.Col key={child.id}>
                {!child.tags?.includes('hide-title') && (
                  <b2x.ConditionalWrapper
                    condition={child.link !== undefined}
                    wrapper={
                      <b2x.router.Link className="text-decoration-none text-white" onClick={close} to={child.link} />
                    }
                  >
                    <h6 className="mb-3 text-uppercase fw-medium">{child.label}</h6>
                  </b2x.ConditionalWrapper>
                )}
                <RecursiveMenuItem deep={deep + 1} item={child} />
              </b2x.Col>
            ))}
          </b2x.Row>
          {item.content &&
            b2x.typedContent<HeaderMenuItemCtaContentType>(item.content, (content) => (
              <div className="d-flex justify-content-center">
                <b2x.CtaFromContent
                  {...content.body.cta}
                  ctaProps={{ button: { className: 'fw-medium btn-sm lh-lg' } }}
                  variant="outline-white"
                />
              </div>
            ))}
        </div>
      ) : (
        <ul className="list-unstyled mb-5">
          {item?.children.map((child) => (
            <li className="lh-1 mb-2" key={child.id}>
              <b2x.router.Link className="small text-white text-decoration-none" onClick={close} to={child.link}>
                {child.label}
              </b2x.router.Link>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

interface LocaleBoxProps {
  toggleLocaleBoxVisible(): void;
}

const LocaleBox = ({ toggleLocaleBoxVisible }: LocaleBoxProps) => {
  return (
    <Box onCloseButtonClick={toggleLocaleBoxVisible}>
      <div className="row justify-content-center text-center">
        <div className="col-5">
          <b2x.LocaleForm />
        </div>
      </div>
    </Box>
  );
};

interface BoxProps {
  children?: React.ReactNode;
  onCloseButtonClick(): void;
}

const Box = ({ children, onCloseButtonClick }: BoxProps) => {
  b2x.useKeyPress('Escape', onCloseButtonClick);

  const ref = React.useRef<HTMLDivElement>(null);

  const handleOutsideClick = React.useCallback(() => {
    onCloseButtonClick();
  }, [onCloseButtonClick]);

  b2x.useOutsideClickHandler(ref, handleOutsideClick);

  return (
    <div className="box py-5" ref={ref}>
      <Container>
        <div className="d-flex justify-content-end">
          <Button iconEnd={{ name: 'delete', size: 25 }} onClick={onCloseButtonClick} variant="blank" />
        </div>
        {children}
      </Container>
    </div>
  );
};
