import './css/style.scss';
import './i18n/i18n';

import { b2x } from '@b2x/react/src';
import React from 'react';
import ReactDOM from 'react-dom/client';

import { AlertFromDto } from './AlertFromDto';
import { App } from './App';
import { BestPrice } from './BestPrice';
import { Button, ButtonVariant } from './Button';
import { Container } from './Container';
import { CustomRadioCheck } from './CustomRadioCheck';
import { NewsletterForm } from './forms/NewsletterForm';
import { en } from './i18n/locales/en';
import { it } from './i18n/locales/it';
import { IconName, IconSize } from './Icon';
import { LoadingOverlay } from './LoadingOverlay';
import { OffcanvasHeader } from './Offcanvas';
import { PriceBlock } from './pages/PriceBlock';
import { ProductTile, ProductTileBasicInfo, ProductTileExtraInfo } from './ProductTile';
import { reportWebVitals } from './reportWebVitals';

b2x.init<ButtonVariant, IconName, IconSize>({
  analytics: {
    baim: {
      debug: false,
    },
    facebook: {
      debug: false,
      pixelId: '813728313705022',
      sendPageView: false,
    },
    googleAds: {
      debug: false,
      id: 'AW-11221857682',
    },
    googleAnalytics4: {
      debug: false,
      id: 'G-820MDGB3JT',
    },
    // universalAnalytics: {
    //   debug: false,
    //   id: 'UA-132449689-1',
    //   sendPageView: false,
    // },
  },
  api: {
    apiKey: 'GIAAJJJAABGJGIEEEGFHIG',
    productPopulate: {
      alternativeImages: true,
      attributes: true,
      bundleProducts: {
        skus: {
          price: true,
        },
      },
      priceRange: true,
      skus: {
        attributes: true,
        price: true,
      },
      tags: true,
      variantProducts: true,
    },
    productTilePopulate: {
      attributes: true,
      priceRange: true,
      skus: {
        attributes: true,
        price: true,
      },
      tags: true,
      variantProducts: true,
    },
    sessionOptions: {
      populate: {
        cart: {
          packs: {
            cartSkus: {
              giftCard: true,
              sku: {
                attributes: true,
                product: {
                  attributes: true,
                },
              },
            },
          },
          shippingProfile: true,
        },
        customer: true,
        wishlist: { products: true, skus: { product: true } },
      },
    },
  },
  cookiebot: {
    id: '0652ecbb-4072-411c-affa-d15a9218b441',
  },
  enableBestPrice: true,
  enableProductAvailabilityEmailNotification: true,
  form: {
    buttons: {
      cancel: {
        defaultVariant: 'outline-secondary',
      },
      submit: {
        defaultVariant: 'primary',
      },
    },
  },
  futureFashion3DViewer: {
    customerId: 'c33bba01-3bd8-49fd-9fbe-6fecd5e401a5',
  },
  // googleMaps: {
  //   apiKey: 'AIzaSyBEAelgBrUvy1nRWfFloemHgVC55qiUIvk',
  // },
  i18n: {
    en: en,
    it: it,
  },

  icons: {
    arrow: {
      left: 'arrow-sx',
    },
    close: 'delete',
    configurator: 'filter',
    download: 'download',
    info: 'info',
    logout: 'logout',
    passwordToggle: {
      hidden: 'hidden',
      visible: 'visible',
    },
    share: 'compare',
  },
  installmentPaymentBanner: {
    paypal: {
      enabled: true,
      layout: 'text',
      text: { color: 'black', logo: 'inline' },
    },
  },
  paypal: {
    merchantClientId: 'Aeu675dG5jPOUsv24EJ9Iw1DftlLTWQKr7EFd1CczHCb8vqeZ4nl5vuyBfUsLhBbSIeJ2KU09p5ko7V4',
  },
  preRelease: true,
  projectName: 'acavallo',
  ui: 'bs5',
  variants: {
    AddressFieldset: 'A',
    AlertFromDto: AlertFromDto,
    BestPrice: BestPrice,
    Button: Button,
    CartAddressFromAddressBookForm: 'A',
    CartAddressesForm: 'A',
    CartSku: 'A',
    ChangePasswordForm: 'A',
    Container: Container,
    CustomRadioCheck: CustomRadioCheck,
    CustomerAddressForm: 'A',
    CustomerForm: 'A',
    GiftCardForm: 'A',
    InvoiceFieldset: 'A',
    LoadingOverlay: LoadingOverlay,
    LocaleForm: 'A',
    LoginForm: 'A',
    NewsletterForm: NewsletterForm,
    OffcanvasHeader: OffcanvasHeader,
    OrderDetails: 'A',
    OrderGuestForm: 'A',
    PriceBlock: PriceBlock,
    ProductReviews: 'A',
    ProductStickyFooter: 'C',
    ProductTile: ProductTile,
    ProductTileBasicInfo: ProductTileBasicInfo,
    ProductTileExtraInfo: ProductTileExtraInfo,
    ReviewFormModal: 'A',
    ReviewsActions: 'A',
    SearchFilters: 'B',
    SimpleSearchForm: 'A',
  },
});

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);

root.render(
  <React.StrictMode>
    <b2x.router.BrowserRouter>
      <b2x.ModalsContext>
        <App />
      </b2x.ModalsContext>
    </b2x.router.BrowserRouter>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
