import './AmbassadorTile.scss';

import { b2x } from '@b2x/react/src';
import classnames from 'classnames';

import { AmbassadorPageContentType } from './contentTypes';

export interface AmbassadorTileProps {
  ambassadorsPerRow?: number;
  page: b2x.PageApiDto;
}

export const AmbassadorTile = ({ ambassadorsPerRow, page }: AmbassadorTileProps) => {
  const currentBreakpoint = b2x.useBreakpoint();

  return (
    <div className="ambassador-tile">
      {page.content &&
        b2x.typedContent<AmbassadorPageContentType>(page.content, (content) => (
          <b2x.router.Link className="text-decoration-none bg-primary d-block" to={page.fullPath}>
            {content.body.preview?.images?.wallpaper && (
              <div className="ambassador-wallpaper">
                <b2x.ImageFromContent fluid {...content.body.preview.images.wallpaper} />
              </div>
            )}
            {content.body.preview?.images?.profile && (
              <div className="ambassador-profile position-relative mx-auto mb-1 mb-lg-3 border border-2 border-white rounded-circle overflow-hidden">
                <b2x.ImageFromContent fluid {...content.body.preview.images.profile} />
              </div>
            )}
            <div className="text-center text-white pb-3 pb-lg-5 px-3">
              {content.body.nationality?.country && (
                <div className="d-flex align-items-center justify-content-center gap-1">
                  {content.body.nationality.image && (
                    <div className="image-nationality">
                      <b2x.ImageFromContent {...content.body.nationality.image} fluid />
                    </div>
                  )}
                  <div>
                    <p
                      className={classnames('fw-medium m-0 lh-1', {
                        'extra-small': b2x.untilBreakpoint('xs', currentBreakpoint),
                      })}
                      style={{ paddingTop: '2px' }}
                    >
                      {content.body.nationality.country}
                    </p>
                  </div>
                </div>
              )}
              <p className={classnames('fw-bold m-0', { h3: !b2x.untilBreakpoint('xs', currentBreakpoint) })}>
                <b2x.EqualHeightElement name={`ambassador-title-${ambassadorsPerRow}`}>
                  {content.body.name} {content.body.surname}
                </b2x.EqualHeightElement>
              </p>
              <p className="fw-medium m-0">{content.body.profession}</p>
            </div>
          </b2x.router.Link>
        ))}
    </div>
  );
};
