import { MenuApiDto, ShopApiDto } from '@b2x/storefront-api-js-client/src/dto';
import { arrow, autoPlacement, autoUpdate, computePosition, offset, shift } from '@floating-ui/dom';
import React from 'react';

import { useMenusApi } from '../api/useMenusApi';
import { useAppContext } from '../AppContext';
import { BadgeProps } from '../Badge';
import { Button } from '../Button';
import { ConditionalWrapper } from '../ConditionalWrapper';
import { useGeolocationModal } from '../GeolocationModal';
import { Div } from '../HTMLElement';
import { Icon } from '../Icon';
import { HeadingSize } from '../interfaces';
import { Portal } from '../Portal';
import { useLocation, useSearchParams } from '../router/router';
import { useNavigate } from '../router/useNavigate';
import { storage } from '../storage';
import { MarkerContent, useGoogleMap } from '../useGoogleMap';
import { useKeyPress } from '../useKeyPress';
import { useShopSearch } from '../useShopSearch';
import { useWindowSize } from '../useWindowSize';
import { getCurrentBreakpoint, sortShopsByDistance, uiClassName, untilBreakpoint } from '../util';
import { Controls } from './Controls';
import { DesktopFiltersToggle } from './DesktopFiltersToggle';
import { DetailsPanel, ServicesProps as DetailsPanelServicesProps } from './DetailsPanel';
import { FiltersPanel } from './FiltersPanel';
import { ListingPanel } from './ListingPanel';
import { Map } from './Map';
import { ShopTile } from './ShopTile';
import { StoreLocatorContext } from './StoreLocatorContext';

const arrowSize = 20;
// const previewCloseButtonSize = 40;

export interface StoreLocatorProps<ButtonVariant, IconName, IconSize> {
  DetailsPanelServices?: React.FunctionComponent<DetailsPanelServicesProps>;
  clustererAboveAvarageColor?: string;
  clustererBelowAvarageColor?: string;
  currentPositionImage?: string;
  debug?: boolean;
  disableVoiceSearch?: boolean;
  filtersRootDirectoryCode?: string;
  filtersToggleButtonVariant: ButtonVariant;
  getDetailsPanelMarkerContent?(shop: ShopApiDto): MarkerContent;
  getFixedFilters?(filters?: MenuApiDto): Array<string> | undefined;
  getMarkerContent?(shop: ShopApiDto): MarkerContent;
  getShopBadges?(shop: ShopApiDto): Array<BadgeProps>;
  getShopDescription?(shop: ShopApiDto): string;
  getShopName(shop: ShopApiDto): string;
  getShopOtherServices?(shop: ShopApiDto): Array<{ icon: string; name: string }> | undefined;
  getShopThumbnailSrc?(shop: ShopApiDto): string;
  googlePlacesCountryRestrictions?: Array<string>;
  hidePhoneButton?: boolean;
  hideShopsInListingPanel?: boolean;
  iconArrowLeft: IconName;
  iconArrowRight: IconName;
  iconClose: IconName;
  iconDirections: IconName;
  iconFilter: IconName;
  iconMyLocation: IconName;
  iconPhoneCall: IconName;
  iconPin: IconName;
  iconSearch: IconName;
  iconShare: IconName;
  iconSpeechRecognition: IconName;
  iconSpeechRecognitionActive: IconName;
  iconZoomIn: IconName;
  iconZoomOut: IconName;
  iconsSize: IconSize;
  searchedPositionImage?: string;
  shopTileHeadingSize?: HeadingSize;
  variantPrimary: ButtonVariant;
  variantSecondary: ButtonVariant;
}

export type StoreLocatorDetailsPanelServicesProps = DetailsPanelServicesProps;

export const StoreLocator = <ButtonVariant extends string, IconName extends string, IconSize extends number>(
  props: StoreLocatorProps<ButtonVariant, IconName, IconSize>
) => {
  const {
    clustererAboveAvarageColor,
    clustererBelowAvarageColor,
    currentPositionImage,
    debug,
    filtersRootDirectoryCode,
    getFixedFilters,
    getMarkerContent,
    getShopName,
    iconClose,
    iconsSize,
  } = props;

  const { routeInfo } = useAppContext();

  const log = React.useCallback(
    (...messages: Array<unknown>) => {
      if (debug) {
        console.log('StoreLocator', messages);
      }
    },
    [debug]
  );

  const basePath = React.useMemo<string>(() => {
    log('basePath', routeInfo?.pathname);
    if (routeInfo?.pathname) {
      if (routeInfo.pathname === '/') {
        return '/';
      } else {
        return `${routeInfo.pathname}/`;
      }
    } else {
      return '/';
    }
  }, [log, routeInfo?.pathname]);

  const [currentPosition, setCurrentPosition] = React.useState<GeolocationPosition>();

  const [visibleShops, setVisibleShops] = React.useState<Array<ShopApiDto>>([]);

  const navigate = useNavigate();
  const { search } = useLocation();
  const [searchParam] = useSearchParams();

  const [filters, setFilters] = React.useState<MenuApiDto | null>();

  const { getMenuByCode } = useMenusApi();

  React.useEffect(() => {
    log('getting filters');
    if (filtersRootDirectoryCode) {
      getMenuByCode(filtersRootDirectoryCode).then((response) => {
        setFilters(response.data);
      });
    } else {
      setFilters(null);
    }
  }, [filtersRootDirectoryCode, getMenuByCode, log]);

  const activeFilterLength = searchParam.getAll('facets').length;

  const { searchResult } = useShopSearch(
    {
      defaultPageSize: -1,
      fixedFilters: getFixedFilters && filters ? getFixedFilters(filters) : undefined,
      populate: {
        items: {
          address: true,
          // currentTimeRange: true,
          // nextTimeRange: true,
          // timeRangeByDays: true,
        },
      },
      thingsToLoadBeforeSearch: [filters],
    },
    {},
    []
  );

  // Uso sortedSearchResult il meno possibile, è meno stabile di searchResult, cambia infatti quando accetto la geolocalizzazione.
  const sortedSearchResult = React.useMemo(
    () =>
      currentPosition && searchResult?.items
        ? { ...searchResult, items: sortShopsByDistance(searchResult.items, currentPosition) }
        : searchResult,
    [currentPosition, searchResult]
  );

  const getShopByPath = React.useCallback(
    (path: string) => {
      log('getShopByPath', path);
      return searchResult?.items?.find((shop) => shop.path !== undefined && shop.path === path);
    },
    [log, searchResult?.items]
  );

  /*
    Devo gestire 5 "stati" dello shop.
     - 1: Default. Uno shop come tanti altri in mappa.
     - 2: Hover (marker). Quanto con il mouse sto sopra al relativo marker.
     - 3: Hover (tile). Quando con il mouse sto sopra al relativo tile del listing.
     - 4: Preview. Quando ha la preview aperta.
     - 5: Active. Quando ha la scheda di dettaglio aperta.
  */

  // Stato 1: Default. Non devo fare nulla.

  // Stato 2: Hover (marker)
  const [markerHoveredShopId, setMarkerHoveredShopId] = React.useState<string>();
  const previousMarkerHoveredShopId = React.useRef<string>();

  // Stato 3: Hover (tile)
  const [tileHoveredShopId, setTileHoveredShopId] = React.useState<string>();
  const previousTileHoveredShopId = React.useRef<string>();

  // Stato 4: Preview
  const [previewShopId, setPreviewShopId] = React.useState<string>();
  const previousPreviewShopId = React.useRef<string>();

  // Stato 5: Active
  const activeShopId = React.useMemo(
    () => (routeInfo?.pathParam ? getShopByPath(routeInfo.pathParam)?.id : undefined),
    [getShopByPath, routeInfo?.pathParam]
  );
  const previousActiveShopId = React.useRef<string>();

  // FINE VARIABILI DI BASE DEDICATE AI 5 STATI

  const activeShop = React.useMemo<ShopApiDto | undefined>(
    () => searchResult?.items?.find((shop) => shop.id === activeShopId),
    [activeShopId, searchResult?.items]
  );

  const activateShop = React.useCallback(
    (shop: ShopApiDto | undefined) => {
      log('activateShop', shop);
      if (shop) {
        navigate({ pathname: `${basePath}/${shop.path}`, search: search }, { scrollTop: false, silent: true });
      } else {
        navigate({ pathname: `${basePath}/`, search: search }, { scrollTop: false, silent: true });
      }
    },
    [basePath, log, navigate, search]
  );

  // // Al cambiare dell'activeShop, faccio la navigate.
  // React.useEffect(() => {
  //   if (previousActiveShopId.current !== activeShop?.id) {
  //     if (activeShop?.name) {
  //       navigate({ pathname: `/${activeShop.path}`, search: search }, { silent: true });
  //     } else {
  //       navigate({ pathname: '/', search: search }, { silent: true });
  //     }
  //   }
  // }, [activeShop, navigate, search]);

  const closeDetailsPanel = React.useCallback(() => {
    log('closeDetailsPanel', window.location.pathname, basePath);
    // setActiveShopId(undefined);
    // metto window.location.pathname e non location di react router, per evitare una dipendenza scomoda.
    // questo if è fragile in lottomatica, in quanto basePath è "/", da rivedere.
    if (!window.location.pathname.endsWith(basePath)) {
      log('closeDetailsPanel', 'navigate');
      navigate({ pathname: `${basePath}/`, search: search }, { scrollTop: false, silent: true });
    }
  }, [basePath, log, navigate, search]);

  const previewShop = React.useMemo<ShopApiDto | undefined>(
    () => searchResult?.items?.find((shop) => shop.id === previewShopId),
    [previewShopId, searchResult?.items]
  );

  const previewShopPortalContainer = React.useMemo(
    () => (previewShop?.id ? document.querySelector(`[data-id="${previewShop.id}"]`) : undefined),
    [previewShop?.id]
  );

  const [listingPanelVisibility, setListingPanelVisibility] = React.useState<boolean>(false);

  const toggleListingPanel = React.useCallback(() => {
    log('toggleListingPanel');
    setListingPanelVisibility((prevState) => !prevState);
  }, [log]);

  const [filtersPanelVisibility, setFiltersPanelVisibility] = React.useState<boolean>(false);

  const toggleFiltersPanel = React.useCallback(() => {
    log('toggleFiltersPanel');
    setFiltersPanelVisibility((prevState) => !prevState);
  }, [log]);

  const closeFiltersPanel = React.useCallback(() => {
    log('closeFiltersPanel');
    setFiltersPanelVisibility(false);
  }, [log]);

  const [ref, googleMap] = useGoogleMap({
    clickableIcons: false,
    clusterer: {
      algorithmOptions: { radius: 70 },
      defaultRendererOptions: {
        aboveAvarageColor: clustererAboveAvarageColor,
        belowAvarageColor: clustererBelowAvarageColor,
        fontSize: 11,
        offset: 0.15,
        opacity: 1,
        size: 55,
      },
    },
    countryCode: 'IT',
    debug: debug,
    // fullscreenControl: false,
    // mapTypeControl: false,
    // streetViewControl: false,
    disableDefaultUI: true,
  });

  const initialized = googleMap !== undefined && searchResult !== undefined;

  // // Al primo caricamento, setto l'activeShopId in base alla path.
  // React.useEffect(() => {
  //   // Questo if serve a non far girare questo useEffect quando non ho ancora scaricato gli shop (getShopByPath tornerebbe undefined).
  //   if (initialized) {
  //     setActiveShopId(shopPath ? getShopByPath(shopPath)?.id : undefined);
  //   }
  // }, [getShopByPath, initialized, searchResult, shopPath]);

  // Cerco gli shop e li imposto su maps
  React.useEffect(() => {
    log('googleMap.setMarkers', searchResult);
    if (googleMap && searchResult?.items) {
      googleMap.setMarkers(
        searchResult.items.map((shop) => {
          return {
            content: getMarkerContent ? getMarkerContent(shop) : undefined,
            eventListeners: [
              [
                'mouseenter',
                () => {
                  setMarkerHoveredShopId(shop.id);
                },
              ],
              [
                'mouseleave',
                () => {
                  setMarkerHoveredShopId(undefined);
                },
              ],
            ],
            id: shop.id,
            listeners: [
              [
                'click',
                (event) => {
                  // Apro la preview, ma solo se non sto cliccando su un figlio della preview stessa, come ad esempio la X per chiuderla.
                  const target = event.domEvent.target as Element;
                  const previewAncestor = target.closest('#preview');
                  if (previewAncestor === null) {
                    setPreviewShopId(shop.id);
                  }
                },
              ],
            ],
            position:
              shop.address?.latitude && shop.address.longitude
                ? { lat: shop.address.latitude, lng: shop.address.longitude }
                : undefined,
            title: getShopName(shop),
          };
        })
      );
    }
  }, [getShopName, googleMap, getMarkerContent, searchResult?.items, log, searchResult]);

  /*
    Gestisco il centramento iniziale della mappa.
     - Se sono atterrato su una friendlyUrl di uno shop, centro sullo shop.
     - Altrimenti, se ho attivato la geolocalizzazione, centro sull'utente.
     - Altrimenti, faccio un fitBounds per vedere tutti gli shop.
  */
  const firstLoad = React.useRef<boolean>(true);
  React.useEffect(() => {
    log('centering map');
    if (googleMap && searchResult && firstLoad.current) {
      const landingShop = routeInfo?.pathParam && getShopByPath(routeInfo.pathParam);
      if (landingShop && landingShop.address?.latitude && landingShop.address.longitude) {
        googleMap.panTo({ lat: landingShop.address.latitude, lng: landingShop.address.longitude });
        googleMap.setZoom(15);
      } else if (
        currentPosition !== undefined
        // && currentPosition !== previousPosition.current
      ) {
        /*
          A differenza degli altri 2, questo lo faccio anche dopo il primo caricamento.
          Potrei infatti accettare la geolocalizzazione in un secondo momento, e anche li voglio fare un pan.
        */
        googleMap.panTo({ lat: currentPosition.coords.latitude, lng: currentPosition.coords.longitude });
        googleMap.setZoom(15);
        // previousPosition.current = currentPosition;
      } else if (searchResult.itemsCount > 0) {
        googleMap.fitBounds();
      }
      firstLoad.current = false;
    }
  }, [currentPosition, getShopByPath, googleMap, log, searchResult, routeInfo?.pathParam]);

  /*
    Potrei accettare la geolocalizzazione in un secondo momento, anche li voglio fare un pan, ma SOLO se non ho un activeShop.
  */
  const previousPosition = React.useRef<GeolocationPosition | undefined>(currentPosition);
  React.useEffect(() => {
    log('centering map after obtaining current position');
    if (googleMap && searchResult) {
      if (currentPosition !== undefined && currentPosition !== previousPosition.current && activeShopId === undefined) {
        googleMap.panTo({ lat: currentPosition.coords.latitude, lng: currentPosition.coords.longitude });
        googleMap.setZoom(15);
      }
      previousPosition.current = currentPosition;
    }
  }, [activeShopId, currentPosition, googleMap, log, searchResult]);

  React.useEffect(() => {
    log('css class', 'marker-hover');
    if (googleMap) {
      if (markerHoveredShopId) {
        if (previousMarkerHoveredShopId.current && markerHoveredShopId !== previousMarkerHoveredShopId.current) {
          googleMap.removeMarkerClassNameById(previousMarkerHoveredShopId.current, 'marker-hover');
        }
        googleMap.addMarkerClassNameById(markerHoveredShopId, 'marker-hover');
        previousMarkerHoveredShopId.current = markerHoveredShopId;
      } else {
        previousMarkerHoveredShopId.current &&
          googleMap.removeMarkerClassNameById(previousMarkerHoveredShopId.current, 'marker-hover');
      }
    }
  }, [googleMap, log, markerHoveredShopId]);

  React.useEffect(() => {
    log('css class', 'tile-hover');
    if (googleMap) {
      if (tileHoveredShopId) {
        if (previousTileHoveredShopId.current && tileHoveredShopId !== previousTileHoveredShopId.current) {
          googleMap.removeMarkerClassNameById(previousTileHoveredShopId.current, 'tile-hover');
        }
        googleMap.addMarkerClassNameById(tileHoveredShopId, 'tile-hover');
        previousTileHoveredShopId.current = tileHoveredShopId;
      } else {
        previousTileHoveredShopId.current &&
          googleMap.removeMarkerClassNameById(previousTileHoveredShopId.current, 'tile-hover');
      }
    }
  }, [googleMap, log, tileHoveredShopId]);

  React.useEffect(() => {
    log('css class', 'preview');
    if (googleMap) {
      if (previewShopId) {
        if (previousPreviewShopId.current && previewShopId !== previousPreviewShopId.current) {
          googleMap.removeMarkerClassNameById(previousPreviewShopId.current, 'preview');
        }
        googleMap.addMarkerClassNameById(previewShopId, 'preview');
        previousPreviewShopId.current = previewShopId;
      } else {
        previousPreviewShopId.current && googleMap.removeMarkerClassNameById(previousPreviewShopId.current, 'preview');
      }
    }
  }, [googleMap, log, previewShopId]);

  React.useEffect(() => {
    log('css class', 'active');
    if (googleMap) {
      if (activeShopId) {
        if (previousActiveShopId.current && activeShopId !== previousActiveShopId.current) {
          googleMap.removeMarkerClassNameById(previousActiveShopId.current, 'active');
        }
        googleMap.addMarkerClassNameById(activeShopId, 'active');
        previousActiveShopId.current = activeShopId;
      } else {
        previousActiveShopId.current && googleMap.removeMarkerClassNameById(previousActiveShopId.current, 'active');
      }
    }
  }, [googleMap, activeShopId, log]);

  const closePreview = React.useCallback(
    (event?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      log('closePreview', event);
      setPreviewShopId(undefined);
    },
    [log]
  );

  useKeyPress('Escape', closePreview);
  useKeyPress('Escape', closeDetailsPanel);
  useKeyPress('Escape', closeFiltersPanel);

  React.useEffect(() => {
    log('addListener', 'click', 'closePreview');
    if (initialized) {
      return googleMap.addListener('click', closePreview);
    }
  }, [closePreview, googleMap, initialized, log]);

  React.useEffect(() => {
    log('addListener', 'click', 'closeDetailsPanel + closeFiltersPanel');
    if (initialized) {
      return googleMap.addListener('click', () => {
        closeDetailsPanel();
        closeFiltersPanel();
      });
    }
  }, [closeDetailsPanel, closeFiltersPanel, googleMap, initialized, log]);

  // Devo aggiungere questi listener solo DOPO il caricamento iniziale, se no mi vanno a interferire con la preselezione del negozio (condivisione url)

  React.useEffect(() => {
    log('addListener', 'zoom_changed', 'closeDetailsPanel + closeFiltersPanel');
    if (initialized) {
      return googleMap.addListener('zoom_changed', () => {
        closeDetailsPanel();
        closeFiltersPanel();
      });
    }
  }, [closeDetailsPanel, closeFiltersPanel, googleMap, initialized, log]);

  React.useEffect(() => {
    log('addListener', 'dragstart', 'closeDetailsPanel + closeFiltersPanel');
    if (initialized) {
      return googleMap.addListener('dragstart', () => {
        closeDetailsPanel();
        closeFiltersPanel();
      });
    }
  }, [closeDetailsPanel, closeFiltersPanel, googleMap, initialized, log]);

  // React.useEffect(() => {
  //   log('addControl', 'geolocator');
  //   return googleMap?.addControl({
  //     control: (
  //       <Div className={uiClassName({ bs5: 'geolocator btn btn-outline-gray-500 text-gray-700 me-2' })}>
  //         <Icon name={iconMyLocation} size={24} />
  //       </Div>
  //     ),
  //     onClick: () => {
  //       if (currentPosition) {
  //         googleMap.panTo({ lat: currentPosition.coords.latitude, lng: currentPosition.coords.longitude });
  //         googleMap.setZoom(15);
  //       } else {
  //         showModal({
  //           children: t('storeLocator.modal.geolocationError.content'),
  //           title: t('storeLocator.modal.geolocationError.title'),
  //         });
  //       }
  //     },
  //     position: google.maps.ControlPosition.RIGHT_BOTTOM,
  //   });
  // }, [currentPosition, googleMap, iconMyLocation, log, showModal]);

  // Quando vado al dettaglio, chiudo la preview.
  React.useEffect(() => {
    log('closing preview after activating shop');
    if (activeShopId !== undefined) {
      closePreview();
    }
  }, [activeShopId, closePreview, log]);

  // Quando cambio preview, chiudo il dettaglio.
  React.useEffect(() => {
    log('closing details and filters panels after previewing shop');
    if (previewShopId !== undefined) {
      closeDetailsPanel();
      closeFiltersPanel();
    }
  }, [closeDetailsPanel, closeFiltersPanel, log, previewShopId]);

  // Funzione per aggiornare il listing con i soli shop visibili in mappa
  const updateVisibleShops = React.useCallback(() => {
    log('updateVisibleShops');
    if (googleMap) {
      const visibleMarkersIds = googleMap.getVisibleMarkersIds();
      setVisibleShops(sortedSearchResult?.items?.filter((shop) => visibleMarkersIds?.includes(shop.id)) ?? []);
    }
  }, [googleMap, log, sortedSearchResult?.items]);

  const windowSize = useWindowSize();

  // Aggiorno gli shop del listing al primo caricamento e a ogni resize della window
  React.useEffect(() => {
    log('updateVisibleShops (first run)');
    updateVisibleShops();
  }, [log, updateVisibleShops, windowSize]);

  // Alla fine di un pan o di uno zoom, aggiorno gli shop del listing con quelli visibili in mappa
  React.useEffect(() => {
    log('addListener', 'idle', 'updateVisibleShops');
    return googleMap?.addListener('idle', updateVisibleShops);
  }, [googleMap, log, updateVisibleShops]);

  const [GeolocationModal, showGeolocationModal] = useGeolocationModal({
    centered: untilBreakpoint('lg', getCurrentBreakpoint()) ? true : false,
    onPermissionGranted: (position: GeolocationPosition) => {
      storage.setBoolean('lottomaticaGeolocationPermission', true, true);
      setCurrentPosition(position);
    },
    size: 'small',
  });

  /*
    All'atterraggio sul sito, se non ho ancora accettato la geolocalizzazione,
    mostro popup dove anticipo l'accettazione nativa del browser.
  */
  React.useEffect(() => {
    log('showing geolocation modal');
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    if (!!navigator.permissions) {
      navigator.permissions.query({ name: 'geolocation' }).then(({ state }) => {
        if (state === 'prompt') {
          showGeolocationModal();
        } else if (state === 'denied') {
          // permesso già negato, ci posso fare poco. Faccio comunque una nuova rischiesta, tanto per far apparire la negazione nella barra in alto
          // eslint-disable-next-line @typescript-eslint/no-empty-function
          navigator.geolocation.getCurrentPosition(() => {});
        } else {
          // permesso già concesso, salvo la position.
          navigator.geolocation.getCurrentPosition(setCurrentPosition);
        }
      });
    } else {
      const geolocationAccessGranted = storage.getBoolean('lottomaticaGeolocationPermission', true);
      if (!geolocationAccessGranted) {
        showGeolocationModal();
      } else {
        navigator.geolocation.getCurrentPosition(setCurrentPosition);
      }
    }
  }, [log, showGeolocationModal]);

  React.useEffect(() => {
    log('updatePosition');
    const markerElement = document.querySelector(`[data-id="${previewShopId}"]`);
    const previewElement = document.getElementById('preview');
    const arrowElement = document.getElementById('arrow');

    const updatePosition = () => {
      if (markerElement && previewElement && arrowElement) {
        computePosition(markerElement, previewElement, {
          middleware: [
            autoPlacement({ padding: 10 }),
            shift({ padding: 10 }),
            offset(arrowSize),
            // hide({
            //   padding: 10,
            // }),
            arrow({
              element: arrowElement,
              padding: arrowSize / 2,
            }),
          ],
        }).then(({ middlewareData, placement, x, y }) => {
          // const { referenceHidden } = middlewareData.hide ?? {};

          Object.assign(previewElement.style, {
            left: `${x}px`,
            top: `${y}px`,
            // visibility: referenceHidden ? 'hidden' : 'visible',
          });

          if (middlewareData.arrow) {
            const { x: arrowX, y: arrowY } = middlewareData.arrow;

            Object.assign(arrowElement.style, {
              bottom: placement === 'top' ? `-${arrowSize - 2}px` : '',
              left: placement === 'right' ? `-${arrowSize * 1.5 - 2}px` : arrowX != null ? `${arrowX}px` : '',
              right: placement === 'left' ? `-${arrowSize * 1.5 - 2}px` : '',
              top: placement === 'bottom' ? `-${arrowSize - 2}px` : arrowY != null ? `${arrowY}px` : '',
              transform:
                placement === 'bottom'
                  ? 'rotate(180deg)'
                  : placement === 'right'
                  ? 'rotate(90deg)'
                  : placement === 'left'
                  ? 'rotate(-90deg)'
                  : '',
            });
          }
        });
      }
    };
    // updatePosition();
    const cleanup =
      markerElement && previewElement
        ? autoUpdate(markerElement, previewElement, updatePosition, {
            ancestorResize: false,
            ancestorScroll: false,
            animationFrame: false,
            elementResize: true,
            layoutShift: false,
          })
        : undefined;
    return cleanup;
  }, [log, previewShopId]);

  // Al cambiare della currentPosition, la imposto su maps, per mostrare in mappa un eventuale indicatore.
  React.useEffect(() => {
    log('setCurrentPosition');
    if (currentPosition) {
      googleMap?.setCurrentPosition(currentPosition, {
        image: currentPositionImage
          ? {
              id: 'current-position',
              src: currentPositionImage,
              width: 24,
            }
          : undefined,
      });
    }
  }, [currentPosition, currentPositionImage, googleMap, log]);

  // const { addOffset, removeOffset } = useFloatingActionButtonContext();

  const previewRef = React.useRef<HTMLDivElement>(null);

  // React.useEffect(() => {
  //   if (previewShopId !== undefined && previewRef.current) {
  //     addOffset('previewShop', previewRef.current.offsetHeight + parseInt(getComputedStyle(previewRef.current).bottom));
  //   } else {
  //     removeOffset('previewShop');
  //   }
  //   return () => {
  //     removeOffset('previewShop');
  //   };
  // }, [addOffset, previewShopId, removeOffset]);

  return (
    <StoreLocatorContext {...props}>
      <Div className={uiClassName({ bs5: 'h-100 position-relative overflow-hidden' })} id="store-locator">
        {googleMap && (
          <>
            <ListingPanel
              activateShop={activateShop}
              activeShopId={activeShopId}
              currentPosition={currentPosition}
              googleMap={googleMap}
              listingPanelVisibility={listingPanelVisibility}
              markerHoveredShopId={markerHoveredShopId}
              previewShopId={previewShopId}
              setTileHoveredShopId={setTileHoveredShopId}
              shops={visibleShops}
              tileHoveredShopId={tileHoveredShopId}
              toggleListingPanel={toggleListingPanel}
            />
            {filters && (
              <>
                <DesktopFiltersToggle
                  detailsPanelVisibility={activeShop !== undefined}
                  filterCount={activeFilterLength}
                  filtersPanelVisibility={filtersPanelVisibility}
                  toggleFiltersPanel={toggleFiltersPanel}
                />
                <FiltersPanel
                  filters={filters}
                  filtersPanelVisibility={filtersPanelVisibility}
                  googleMap={googleMap}
                  toggleFiltersPanel={toggleFiltersPanel}
                />
              </>
            )}
            <DetailsPanel closeDetailsPanel={closeDetailsPanel} googleMap={googleMap} shop={activeShop} />
            <Controls
              currentPosition={currentPosition}
              filterCount={activeFilterLength}
              googleMap={googleMap}
              toggleFiltersPanel={toggleFiltersPanel}
            />
          </>
        )}
        <Div id="map-wrapper">
          <Map innerRef={ref} />
          {previewShop && googleMap && previewShopPortalContainer && (
            <ConditionalWrapper
              condition={isDesktop(windowSize.width)}
              wrapper={<Portal container={previewShopPortalContainer} />}
            >
              <Div id="preview" innerRef={previewRef}>
                <Button className="on-map-item with-color rounded-circle close" onClick={closePreview} variant="plain">
                  <Icon name={iconClose} size={iconsSize} />
                </Button>
                {/* <BlankButton
                  className={classNames('on-map-item', uiClassName({ bs5: 'bg-white' }))}
                  onClick={closePreview}
                  style={{
                    alignItems: 'center',
                    borderRadius: previewCloseButtonSize / 2,
                    display: 'flex',
                    height: previewCloseButtonSize,
                    justifyContent: 'center',
                    position: 'absolute',
                    right: 0,
                    top: -previewCloseButtonSize - 5,
                    width: previewCloseButtonSize,
                  }}
                >
                  <Icon name={iconClose} size={16} />
                </BlankButton> */}
                <ShopTile
                  activateShop={activateShop}
                  className="on-map-item"
                  currentPosition={currentPosition}
                  googleMap={googleMap}
                  shop={previewShop}
                />
                <Div
                  id="arrow"
                  style={{
                    borderLeft: `${arrowSize}px solid transparent`,
                    borderRight: `${arrowSize}px solid transparent`,
                    borderTop: `${arrowSize}px solid white`,
                    height: 0,
                    position: 'absolute',
                    width: 0,
                  }}
                />
              </Div>
            </ConditionalWrapper>
          )}
        </Div>
        {GeolocationModal}
      </Div>
    </StoreLocatorContext>
  );
};

export const isMobile = (width: number) => width < 992;

export const isDesktop = (width: number) => !isMobile(width);
